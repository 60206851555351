@import (reference) "styles/theme.less";

.label {
  text-transform: uppercase;
  line-height: 12px;
  letter-spacing: 0em;
  font-size: 10px;
  font-weight: 700;
  color: var(--nepal-blue);
}

.permLabel {
  .label();
  margin-top: 12px;
  margin-left: 6px;
}

.drawerMask {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-a30);
  overflow: hidden;
  z-index: 1001;
}

.tableContainer {
  max-width: 900px;

  .table {
    margin-top: 0;
  }

  .column {
    height: 100%;
    padding: 8px 16px;
  }

  .row {
    cursor: pointer;
  }

  .rowWhite {
    background-color: var(--white);
  }

  .headerCell {
    border-bottom: 1px solid var(--slate-grey);
  }

  .centeredColumn {
    .column();
    span {
      justify-content: center;
    }
  }

  .subColumn {
    .centeredColumn();
    padding: 4px 16px;
  }

  .permissionColumn {
    .centeredColumn();
    span {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }

  .roleColumn {
    background-color: var(--slate-grey);
  }

  .columnHeader {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    width: 100%;
  }

  .cell {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    text-transform: capitalize;
  }

  .centeredCell {
    .cell();
    align-items: center;
  }

  .centeredCellDate {
    .centeredCell();
    font-size: 9px;
    text-align: center;
  }

  .avatar {
    width: 75px;
    height: 75px;
    border: 1px solid var(--slate-grey-a65);
    border-radius: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .nameContainer {
    padding-left: 8px;
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .roleContainer {
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    .editIcon {
      display: inline-block;
      padding: 5px 10px 0 0;
      text-align: left;
      font-size: 16px;
      .blue();
      cursor: pointer;
    }
  }

  .name {
    font-size: 14px;
    .ellipsis();
  }

  .small {
    font-size: 12px;
    .ellipsis();
  }

  .bold {
    .name();
    font-weight: 600;
  }

  .email {
    .small();
    color: var(--primary-color);
  }
}

.cardsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 0 3px;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding-top: 75px;
    background-color: rgba(240, 242, 245, 0.45);
    z-index: 6;

    .spin {
      z-index: 7;
    }
  }

  .paginationContainer {
    width: 100%;
    margin-top: 10px;

    .pagination {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .emptyContainer {
    width: 100%;
    background-color: var(--white);
  }

  .card {
    position: relative;
    width: 100%;
    max-width: 576px;
    margin: 10px 5px;
    margin-bottom: 0;
    border-radius: 5px;
    padding: 20px;
    background-color: var(--white);
    cursor: pointer;
    .buttonShadow();

    .openDrawer {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 25px;
      background-color: var(--light-grey);
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

      .openDrawerIcon {
        color: var(--slate-grey);
        font-size: 12px;
      }

      .openDrawerText {
        font-size: 10px;
        text-align: center;
      }
    }

    .row {
      margin: 0;
      .ellipsis();
    }

    .clickable {
      cursor: pointer;
    }

    .headline {
      font-size: 14px;
    }

    .text {
      font-size: 12px;
    }

    .metricRow {
      display: flex;
      align-items: flex-end;
    }

    .valueCol {
      display: flex;
      justify-content: flex-end;
    }

    .value {
      font-size: 10px;
    }

    .email {
      .text();
      color: var(--primary-color);
    }

    .detail {
      font-size: 10px;
      color: rgba(128, 128, 128, 0.65);
      text-transform: uppercase;
    }

    .icon {
      font-size: 18px;
      color: var(--light-grey);
    }
  }
}

.userDetailDrawer {
  width: 100%;
  height: calc(100vh - 400);
  // overflow: hidden;
  position: relative;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color-a30);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .mainContent {
    position: relative;
    width: auto;
    height: calc(100vh - 50px);
    .tabs {
      height: 100%;

      :global(.ant-tabs-nav) {
        height: 56px;
        padding-left: 32px;
        margin-bottom: 0;
        z-index: 100;
        background-color: var(--white);
        padding: 0px 20px;
        height: 50px;
        padding-top: 3px;
        margin-bottom: 0;
      }

      :global(.ant-tabs-tab:not(:first-of-type)) {
        margin: 0 0 0 20px !important;
      }

      .tabWindow {
        height: calc(100vh - 100px);
        overflow-y: auto;
      }

      :global(.ant-tabs-nav-wrap) {
        box-shadow: none;
      }

      .tabLabel {
        font-size: 14px;
        margin: 0 12px;
        font-weight: 500;
        font-family: Poppins;
      }

      .mobileTabSelect {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        .dropdown {
          width: 275px;
          max-width: 100%;
          color: var(--primary-color);
        }
      }
    }
  }

  .userInfoTab {
    width: auto;
    height: 100%;
    padding: 40px 25px;

    .section {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .licenseDisplay {
      margin: 25px 0;
    }

    .metricsContainer {
      background-color: var(--white);
      border-radius: 4px;
      padding: 23px 24px;
      max-width: auto;

      .headerRow {
        padding: 4px 0;
        margin-bottom: 4px;
        font-size: 10px;
      }
    }

    .userActionsContainer {
      border-radius: 12px 12px 0px 0px;
      .buttonsContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0px 10px 10px;
        border-radius: 4px;
        background-color: var(--white);

        .btn {
          height: 28px;
          font-size: 12px;
          margin-right: 6px;
          margin-bottom: 6px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
          border-radius: 18px;
          line-height: 18px;
          font-weight: 600;
        }

        .button {
          .btn();
          // antd popover causing problems, this and the above repetition fixes
          button {
            .btn();
            width: 100%;
          }
        }
        // Added because antd was overwriting styles in popconfirm when button disabled
        button:disabled,
        button[disabled] {
          .btn();
          color: var(--nepal-blue);
          border: none;
          box-shadow: none;
          background-color: var(--botticelli-blue);
          button {
            .btn();
            color: var(--nepal-blue);
            border: none;
            box-shadow: none;
            background-color: var(--botticelli-blue);
          }
        }

        .cancellingButton {
          color: var(--scarlet);
          background: var(--white);
          border-color: var(--scarlet);

          .btn();
          // antd popover causing problems, this and the above repetition fixes
          button {
            .btn();
            width: 100%;
          }
        }
      }
    }
  }

  .mentorReviewsTab {
    .content {
      :global(.ant-rate-star:not(:last-child)) {
        margin-right: 2px;
      }

      // typography
      .metricHeaderText {
        font-size: 9px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        color: var(--dark-blue);
        text-transform: uppercase;
      }

      .mentorNameText {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--primary-color);
      }

      .menteeNameText {
        .mentorNameText();
        font-size: 12px;
        font-weight: 500;
      }

      .cityText {
        font-size: 12px;
        font-weight: 300;
        line-height: 17px;
        letter-spacing: 0em;
      }

      .numberText {
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0em;
        color: var(--gothic-blue);
      }

      .dateText {
        font-size: 9px;
        font-weight: 300;
        line-height: 12px;
        letter-spacing: 0em;
        color: var(--table-badge-total);
        text-transform: uppercase;
      }

      // components
      .userInfoHeader {
        width: 100%;
        min-height: 90px;
        background-color: var(--emptiness-white);
        display: flex;
        align-items: center;
        padding: 0 26px;
        box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.1);

        .avatarRow {
          display: flex;
          align-items: center;

          .avatarCol {
            margin-right: 14px;
          }

          .mentorNameText {
            margin-bottom: -3px;
          }
        }

        .metricsContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .metricRow {
            width: 230px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 18px;

            .metricHeaderText {
              width: 95px;
              padding-top: 2px;
            }

            .numberText {
              margin-top: 0px;
              margin-left: 5px;
            }

            .metricCol {
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }
        }

        .overallContainer {
          .metricsContainer();
          align-items: center;

          .metricRow {
            justify-content: center;
          }
        }
      }

      .mobileUserInfoHeader {
        .userInfoHeader();
        min-height: 147px;

        .metricHeaderText {
          margin-bottom: -3px;
          padding-top: 1px;
        }
        .avatarRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          .avatarContainer {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          .overallContainer {
            align-items: flex-end;
            .overallMetricRow {
              display: flex;
              flex-direction: column;
              align-items: center;
              .star {
                margin-right: 4px;
              }
            }
          }
        }
        .metricRow {
          display: flex;
          width: 100%;
          display: flex;
          justify-content: space-around;
          .metricCol {
            text-align: center;
          }
          .ratingRow {
            display: flex;
            flex-direction: column;
            align-items: center;
            .star {
              margin-right: 4px;
              width: 100%;
              display: block;
            }
          }
        }
      }
      .tableContainer {
        padding: 24px;
      }
    }
  }

  .userRolesTab {
    .userInfoTab();

    .table {
      .row {
        background-color: var(--white);
        cursor: default;
      }

      .removeButton {
        font-size: 12px;
        text-transform: uppercase;
      }
    }

    .selectWrapper {
      margin-top: 20px;

      .selectContainer {
        width: 100%;
        display: flex;
        // justify-content: space-between;

        .select {
          width: 300px;
          color: var(--primary-color);
          margin-right: 10px;
        }

        .button {
          width: 100px;
          font-size: 12px;
          text-transform: uppercase;
        }
      }
    }
  }

  .fallback {
    .header {
      background-color: var(--primary-color);
      height: 50px;
    }
  }

  .sortsContainer {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: -12px;

    div {
      z-index: 1;
    }

    .mentorLookup {
      :global(.ant-select-selection-search-input) {
        border-radius: 18px;
        border: none;
        input {
          font-size: 12px;
        }
      }
      :global(.anticon-close) {
        color: var(--primary-color);
      }
      :global(.ant-select-selection-placeholder) {
        font-size: 12px;
      }
    }
    .label {
      .label();
      font-weight: 500;
      font-size: 9px;
      line-height: 12px;
      color: var(--gothic-blue);
      margin-bottom: 2px;
    }
    .mentorSelectCol {
      margin-left: 14px;
      .mentorLookup();
      .select {
        width: 150px;
        height: 32px;
      }
      :global(.ant-select-selector) {
        height: 32px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
        border-radius: 18px;
        border: 1px solid var(--white);
      }
      :global(.ant-select-selection-item) {
        font-size: 12px;
        line-height: 30px !important;
        color: var(--primary-color);
      }
      .option {
        color: var(--primary-color);
      }
    }
  }
}

.changeSponsor {
  width: 100%;

  .sponsorInfo {
    margin-bottom: 10px;
    width: 100%;

    .bodyRow {
      width: 100%;
    }

    .sponsorInfoLabel {
      line-height: 16px;
      height: 16px;
      font-weight: 600;
      margin-top: 5px;
    }
    .sponsorInfoValue {
      line-height: 16px;
      height: 16px;
      margin-top: 5px;
      .ellipsis();
    }
  }

  .selectSponsor {
    width: 100%;
  }
}

.accountAuthContainer {
  background-color: var(--white);
  border-radius: 4px;
  padding: 23px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .selectAuthType {
    width: 520px;

    :global(.ant-select-selector) {
      border-radius: 4px;
    }
  }

  .saveAuthType {
    height: 24px;
    width: 100px;
    border-radius: 18px;
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0em;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 15%);
  }
}

.reportContainer {
  margin-top: 50px;

  .label {
    .label();
    padding-top: 4px;
  }

  .subLabel {
    .label();
    padding-top: 2px;
  }

  .input {
    width: 100%;
    padding: 0;
    padding-top: 12px;
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--font-color-eastern-blue);
    text-transform: capitalize;
    color: var(--font-color-eastern-blue);
    font-weight: 500;
    font-size: 14px;

    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      color: var(--graham-grey);
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .button {
    margin: auto;
    margin-top: 10px;
    background: var(--font-color-eastern-blue);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    color: var(--white);
    border: none;
  }
}

.addRoleButton {
  .roundButton();
  .blue();
  margin-top: 16px;
  height: 32px;
  font-size: 12px;
}

.addRoleForm {
  .input {
    .blue();
  }
}

.teamsTabContainer {
  width: 100%;
}

.rolesListContainer {
  margin-top: 40px;
}

.permissionsContainer {
  width: 100%;
  max-width: 700px;
  overflow: auto;
  padding: 16px 24px 8px 0;
  display: flex;
  flex-direction: column;

  .spinContainer {
    position: relative;
    width: 100%;
    left: 0;
    top: 50px;
    display: flex;
    justify-content: center;
  }

  .permission {
    position: relative;
    width: 100%;
    padding: 24px 12px;
    padding-top: 0;
    background-color: var(--white);
    .buttonShadow();
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 16px;

    .expandRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 11px;
      color: var(--slate-grey);
      text-transform: uppercase;
      height: 40px;
    }

    .titleContainer {
      display: flex;
      align-items: baseline;

      .title {
        color: var(--slate-grey);
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        .ellipsis();
      }

      .buttonContainer {
        margin: 0 12px;
      }
    }

    .expandIcon {
      top: 6px;
      right: 12px;
      font-size: 12px;
      color: var(--slate-grey-a65);
      transition: transform 0.15s ease-in-out;
      cursor: pointer;
    }

    .subtitle {
      margin-top: 16px;
      font-size: 11px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .inputRow {
      width: 100%;
      display: flex;
      align-items: baseline;
      padding-right: 16px;

      .buttonContainer {
        display: flex;
        justify-content: flex-end;
      }
    }

    .formItem {
      margin: 4px 0 0 6px;
      max-width: 600px;

      .input {
        .blue();
      }
    }

    .tagsDisplay {
      margin-top: 6px;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.optionDropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 10px;

  .valueLabel {
    .blue();
    font-weight: 600;
  }

  .value {
    font-style: italic;
    margin-left: 8px;
  }

  .actionsContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .icon {
      .blue();
      margin-right: 8px;
      font-size: 12px;

      &:hover {
        transform: scale(1.25);
        transition: transform 0.15s linear;
      }
    }
  }
}

.tag {
  .mention();
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 2px 8px;
}

.smallTag {
  .tag();
  margin: 2px;
  padding: 2px 4px;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
}

.closeableTag {
  .tag();
  position: relative;
  padding-right: 28px;

  .closeIcon {
    cursor: pointer;
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 14px;
  }
}

.managerSelectContainer {
  max-width: auto;
  width: 100%;
  background-color: var(--white);
  border-radius: 4px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 28px 24px;
}

.autoComplete {
  max-width: 520px;
  div {
    border-radius: 4px !important;
  }
}

:global(.ant-dropdown-menu) {
  top: -4px;
  padding: 0px;
  // background-color: aqua;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  // z-index: 9;

  :global(.ant-dropdown-menu-item) {
    font-size: 12px;
    line-height: 10px;
  }
}

:global(.ant-dropdown-menu-item-active) {
  &:hover {
    &:first-child {
      background-color: var(--primary-color-a10);
      border-radius: 18px 18px 0px 0px;
    }
    &:last-child {
      background-color: var(--primary-color-a10);
      border-radius: 0px 0px 18px 18px;
    }
  }
}

:global(.terminateSubscriptionPopover) {
  :global(.ant-popover-inner) {
    border-radius: 12px;
  }
  :global(.ant-popover-title) {
    border-radius: 12px 12px 0px 0px;
    background-color: var(--alabaster-white);
    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    display: flex;
    align-items: center;
    height: 37px;
  }
  :global(.ant-popover-inner-content) {
    border-radius: 0px 0px 18px 12px;

    .popoverBody {
      max-width: 288px;
      padding: 2px 0px 14px 0px;
      .bold {
        font-weight: 600;
      }
    }
    .popoverbuttons {
      display: flex;
      justify-content: space-between;

      .confirmCancel {
        background: var(--scarlet);
        color: var(--white);
        border-color: var(--scarlet);
        border-radius: 18px;
        font-size: 12px;
        font-weight: 600;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      }
      .stopCancel {
        color: var(--primary-color);
        background: var(--white);
        border-color: var(--primary-color);
        border-radius: 18px;
        font-size: 12px;
        font-weight: 500;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.violationChangesModalContent {
  width: 450px;
  color: var(--darkest-blue);
  font-weight: 400;
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;