@import (reference) "styles/theme.less";

.layout {
  height: 100vh;

  .content {
    border-width: 4px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-baby-blue);

    .container {
      width: 150px;
      display: flex;
      flex-direction: column;

      .logo {
        transform: translateY(-70px);
        height: 75px;
        width: 100%;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;