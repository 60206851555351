@import (reference) "styles/theme.less";

.accountTypeContainer {
  width: 100%;
  height: 100%;

  :global(.ant-form-item-with-help .ant-form-item-explain) {
    min-height: 0px;

    :global(.ant-form-item-explain-error) {
      font-size: 10px;
      position: absolute;
      margin-top: -9px;
      margin-right: 5px;
      text-align: right;
      width: 100%;
    }
  }

  .label {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 20px;
    color: var(--blue-ribbon);
    margin-top: 10px;
    line-height: 22px;
  }

  .goBackLinkContainer {
    .goBackLink {
      font-family: "Barlow";
      font-weight: 500;
      font-size: 16px;
      color: var(--text-dark);
      font-style: italic;
      cursor: pointer;
    }
  }

  .continueButton {
    margin: 15px 0px;
    width: 145px;
    height: 45px;
  }

  .joinTypeContainer {
    margin-left: 5px;

    .joinTypelabel {
      .label();
      margin-left: 20px;
      margin-bottom: 10px;
    }

    .joinTypeButtons {
      margin-top: 25px;
    }
  }

  .experienceLevelContainer {
    padding: 0px 25px;
    height: 324px;

    .experienceForm {
      .experienceRadioGroup {
        margin-top: 10px;

        :global(.ant-radio-wrapper) {
          font-family: "Poppins";
          color: var(--text-dark);
          font-weight: 300;
          line-height: 21px;
          display: flex;
          align-items: flex-start;

          :global(.ant-radio-inner) {
            height: 25px;
            width: 25px;
            border-width: 3px;
          }
        }

        :global(.ant-space-item:last-child) {
          :global(span.ant-radio + *) {
            padding-top: 5px;
          }
        }
      }
    }
  }

  .orgDetailsContainer {
    padding: 10px 25px;
    height: 325px;

    input {
      height: 42px;
      border-radius: 3px;
      border: none;
      margin: 7px 0px;
      color: var(--blue-ribbon);
      font-family: "Barlow";
      font-weight: 500;
      font-size: 16px;
    }

    ::placeholder {
      font-family: "Barlow";
      font-weight: 500;
      font-size: 16px;
      color: var(--trolley-grey);
      text-transform: capitalize;
    }

    .employeeCountSelect {
      :global(.ant-select-selector) {
        margin: 5px 0px;
        height: 42px;
        border: none !important;

        :global(.ant-select-selection-item) {
          color: var(--blue-ribbon);
          font-family: "Barlow";
          font-weight: 500;
          font-size: 16px;
        }
      }
      :global(.ant-form-item-control-input) {
        width: 60%;
      }
    }

    :global(.ant-select-arrow) {
      margin-top: -25px;

      :global(.caretIcon) {
        height: 24px;
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;