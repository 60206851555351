@import (reference) "styles/theme.less";

.plansContainer {
  width: 100%;
  background-image: url("/img/background-lines-top-right-4.png"),
    linear-gradient(90deg, var(--onahau-blue) 0%, var(--sparkling-snow) 100%);
  position: relative;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: auto;
  height: 100%;
  padding: 100px 30px 100px 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 900px) {
      padding-left: 15%;
      padding-right: 15%;
    }
    @media (max-width: 600px) {
      padding: 30px 20px 50px 20px;
    }

    .headerContainer {
      margin-bottom: 50px;
      .sectionTitle {
        font-family: Poppins;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
        color: var(--table-grey);
      }

      .description {
        font-family: "Barlow";
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
        text-align: center;
        color: var(--table-grey);
      }
    }

    .cardsContainer {
      background: var(--white);
      padding: 50px;
      width: fit-content;
      border-radius: 10px;

      @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
      }

      .card {
        width: 472px;
        min-height: 779px;
        background-color: var(--white);
        margin: 20px;
        padding: 20px 20px 40px 20px;
        border-radius: 12px;

        @media (max-width: 530px) {
          width: 100%;
          margin: 20px 0px 20px 0px;
        }

        &:nth-child(2) {
          .bulletsContainer > .bullet {
            &:nth-child(1) {
              .bulletText {
                font-weight: bold !important;
              }
            }
          }
        }
      }

      .hiddenCard {
        .card();
        min-height: 100%;
        padding: 20px;
      }

      .bulletsContainer {
        margin: 15px 15px 40px 15px;

        @media (max-width: 800px) {
          margin: 0px;
        }

        .bullet {
          margin: 15px;
          display: flex;
          align-items: center;

          .bulletText {
            font-size: 14px;
            font-weight: 300;
            margin-left: 10px;
          }
        }
      }

      .viewMore {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .button {
          border: none;
          border-radius: 87px;
          box-shadow: none;
          height: 32px;

          span {
            text-transform: unset;
            color: var(--primary-color);
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            padding: 0 12px;
            transition: all 0.5s;
          }
        }
      }
    }
  }
}

.modal {
  :global(.ant-modal-content) {
    background: linear-gradient(95.7deg, var(--maya-blue) 12.17%, var(--blue-ribbon) 93.24%);
    width: fit-content;
  }
  :global(.ant-modal-body) {
    position: relative;
    border-radius: 0px 0px 12px 12px;
  }
  :global(.ant-modal-header) {
    border: none;
    background: transparent;
  }
  :global(.ant-modal-close-x) {
    svg {
      color: var(--white);
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .modalTitle {
      padding: 20px 70px 40px;
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 35px;
      line-height: 40px;
      text-align: center;
      color: var(--white);

      @media (max-width: 600px) {
        font-size: 28px;
        padding: 20px 0px 0px 0px;
      }
    }

    .cardsContainer {
      // padding-top: 40px;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 960px) {
        flex-direction: column;
        align-items: center;
      }

      .cardHeader {
        margin: 20px;
        background-color: var(--white);
        width: 100%;
        max-width: 450px;
        padding: 50px 10px 30px 10px;
        border-radius: 12px;

        @media (max-width: 600px) {
          padding: 30px 0px 30px 0px;
        }

        .planTitle {
          max-height: 29px;
        }
      }

      .footerCard {
        .cardHeader();
        margin-top: 10px;
        margin-bottom: 25px;
        padding: 20px 30px;
        font-family: "Barlow";
        font-size: 16px;
        font-weight: 400;

        .footerText {
          color: var(--darker-blue);
        }

        .footerLink {
          color: var(--blue-ribbon);
          cursor: pointer;
        }
      }
    }
  }
}

.cardHeader {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 35px 0;

  @media (max-width: 530px) {
    margin: 20px 0px 20px 0px;
  }

  .planType {
    color: var(--hit-gray);
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 15px;
  }

  .planTitle {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 34px;
    line-height: 25px;
    text-align: center;
    color: var(--darker-blue);

    .titleGradient {
      background: linear-gradient(95.7deg, var(--heliotrope-magenta) 12.17%, var(--trusted-purple) 93.24%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .proPrice {
    width: 100%;
    margin: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";

    .symbol {
      font-weight: 500;
      font-size: 20px;
      align-self: flex-start;
    }

    .price {
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
    }

    .frequency {
      .symbol();
      align-self: flex-end;
    }
  }

  .button {
    height: 38px;
    width: 156px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white);
  }

  .disclaimer {
    margin-top: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: var(--hit-gray);
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;