@import (reference) "styles/theme.less";

.enterpriseApplicationContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;

  .enterpriseApplication {
    width: 720px;
    height: fit-content;
    border-radius: 8px;
    background-color: var(--white);

    .header {
      height: 164px;
      background-image: url("/img/background-lines-top-right-2.png"),
        linear-gradient(95.7deg, var(--picton-blue) 12.17%, var(--blue-ribbon) 93.24%);
      background-position: top right;
      background-repeat: no-repeat;
      border-radius: 8px 8px 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .headerTitle {
        font-family: "Poppins";
        font-weight: 700;
        font-size: 32px;
        line-height: 27px;
        color: var(--white);
        margin: 10px;
      }

      .headerDetails {
        font-family: "Barlow";
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: var(--white);
        margin: 5px;
      }
    }

    .enterpriseApplicationContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 164px);

      .applicationBody {
        margin: 25px;
      }

      .confirmationText {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        color: var(--table-grey);

        .boldText {
          font-weight: 700;
        }

        .confirmEmail {
          margin: 20px;
        }
      }

      .buttonContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 50px;

        .nextStepButton {
          width: 288px;
          height: 54px;
          border-radius: 96px;
          text-transform: capitalize;
          font-family: "Poppins";
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          color: var(--white);
        }

        .cancelButton {
          font-family: "Poppins";
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          color: var(--blue-ribbon);
          margin: 10px;
        }
      }
    }
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;