@import (reference) "styles/theme.less";

@margin-top: 25px;
@header-height: 50px;
@vh-minus-header: 100vh - @header-height;

.giveMargin {
  margin-top: @margin-top;
}

.giveLightColor {
  color: var(--white);
}
.giveDarkColor {
  color: var(--slate-grey-a65);
}

.cardWithBackground {
  background: var(--white);
  border-radius: 10px;
  padding: 25px;
  margin: 50px;
  width: fit-content;
  background-image: url("/img/background-lines-top-right-3.png");
  background-size: 350px;
  background-position: right top;
  background-repeat: no-repeat;
}

.paymentInfoContainer {
  padding: 0px 20px;

  .inputLabel {
    font-size: 10px;
    font-weight: 700;
    line-height: 9px;
    letter-spacing: 0em;
    color: var(--dark-periwinkle);
    text-transform: uppercase;
  }

  .paymentHeader {
    color: var(--blue-ribbon);
    font-size: 44px;
    font-weight: 600;
    font-family: "Poppins";
  }

  .accountTypeContainer {
    margin-top: 25px;

    .selected {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: var(--darkest-blue);
    }

    .accountTypeHeader {
      font-weight: 600;
      font-size: 32px;
      font-family: "Poppins";
    }

    .accountTypeDetails {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .description {
        color: var(--dark-grey);
        font-weight: 500;
        width: 275px;
      }

      .price {
        color: var(--darkest-blue);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 200px;

        .subscription {
          .cost {
            font-weight: 600;
            font-size: 44px;
            line-height: 53px;
            font-family: "Poppins";
          }

          .frequency {
            font-weight: 600;
            font-size: 20px;
            font-family: "Poppins";
          }
        }

        .setupFee {
          font-weight: 500;
          line-height: 17px;
          font-family: "Poppins";
        }
      }
    }
  }

  .backToAccountTypes {
    color: var(--dark-grey);
    font-weight: 500;
    width: 260px;
    margin: 25px 0px;

    .accountTypesLink {
      cursor: pointer;
      color: var(--blue-ribbon);
    }
  }

  .addressFormContainer {
    margin: 40px 0px;

    .input {
      color: var(--dark-blue);
      margin-top: 3px;
      margin-bottom: 20px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: 0em;
      border: 1px solid var(--botticelli-blue);
      margin-bottom: 8px;

      input {
        height: 36px;
        border: 1px solid var(--botticelli-blue);
      }
    }

    .zip {
      max-width: 20%;
    }

    .state {
      min-width: 30%;
    }

    .select {
      .input();
      border: none;
      margin-bottom: 10px;

      :global(.ant-select-arrow) {
        height: 22px;
        width: 20px;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--dark-blue);
        background: var(--white);
        right: 9px;
      }

      :global(.ant-select-selector) {
        border: 1px solid var(--botticelli-blue);

        input {
          font-weight: 500;
        }
      }

      :global(.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
        border: 1px solid var(--botticelli-blue);
        font-weight: 500;
      }

      :global(.ant-select-selection-item) {
        display: flex;
        align-items: center;
        font-weight: 500;
      }
    }

    :global(.ant-form-item-with-help .ant-form-item-explain) {
      min-height: 0px;

      :global(.ant-form-item-explain-error) {
        position: absolute;
        font-size: 10px;
        width: 100%;
        text-align: right;
        flex-wrap: wrap;
        margin-top: -8px;
      }
    }
  }

  .stripeFormContainer {
    .stripeInput {
      border: 1px solid var(--botticelli-blue);
      border-radius: 3px;
      height: 36px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      margin-top: 3px;
      margin-bottom: 8px;
    }

    .buttonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 70px 15px 15px;

      .purchaseButton {
        width: 238px;
        height: 45px;
      }
    }
  }
}

.termsAndConditions {
  .giveMargin();
  text-transform: none;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 200;
}

.termsAndConditionsDark {
  .termsAndConditions();
  margin: 0;
  .giveDarkColor();
}
.termsAndConditionsLight {
  .termsAndConditions();
  margin: 0;
  .giveLightColor();
}
.billingCheck {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .text {
    margin-right: 10px;
    color: var(--white-a65);
  }
}

.termsCheckbox {
  display: flex;
  align-items: center;

  :global(.ant-checkbox) {
    margin-right: 6px;
  }
}

@font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@s3-asset-path: https://powur-public2.s3-us-west-1.amazonaws.com/assets;@max-container-width: 1280px;@max-content-width: 800px;